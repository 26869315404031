import React from 'react'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Layout from 'src/components/layout'
import { Link, graphql } from 'gatsby'
import { FiCalendar } from 'react-icons/fi'
import Form from 'src/components/form'
import Row from 'src/components/row'
import CTA from 'src/components/cta'
import Reviews from 'src/components/reviews'
import Lightbox from 'react-image-lightbox'

const Page = ({
  data: {
    site,
    imgPipes,
    imgBackflowValve,
    imgBackflowTrench,
    imgSampleLetter,
    wattsLogo,
    zurnLogo,
  },
}) => (
  <Layout>
    <Helmet>
      <title>
        Backflow Preventer Testing and Installation - {site.siteMetadata.title}
      </title>
    </Helmet>

    <div className="content">
      <Row columns={3}>
        <div className="frame">
          <div className="banner">Book Appointment Today</div>
          <div class="frame-content">
            <Form
              button={
                <>
                  <FiCalendar /> Book an Appointment
                </>
              }
            />
          </div>
        </div>
        <div style={{ flexBasis: '66%' }}>
          <h2>
            We Sell, Install, Test and Maintain Backflow Preventers for over 43
            years
          </h2>
          <p>
            Have you received <strong>a warning letter</strong> from the{' '}
            <a
              title="City of Toronto Backflow Information"
              href="http://www.toronto.ca/water/protecting_quality/backflow_prevention/"
              target="_blank"
              rel="noopener noreferrer"
            >
              City of Toronto
            </a>
            ,{' '}
            <a href="http://www.peelregion.ca/pw/water/sewage-trtmt/basement-flooding.htm">
              Region of Peel
            </a>
            ,{' '}
            <a href="http://www.halton.ca/cms/One.aspx?portalId=8310&amp;pageId=12003">
              Halton Region
            </a>
            ,{' '}
            <a href="http://www.markham.ca/wps/portal/Markham/Residents/Water/BackflowPreventionProgram/!ut/p/c4/04_SB8K8xLLM9MSSzPy8xBz9CP0os3gTiyBHZzc_g0Bz8xBTA09DJ09HLzdnY0tnQ_2CbEdFAGT-VwI!/">
              Town of Markham
            </a>
            &nbsp;or other municipality demanding that you&nbsp;
            <strong>
              test or install a backflow preventer or check valve at your
              commercial, residential, or multi-unit property
            </strong>
            ? Are you concerned about the quality of your water supply, and want
            to protect yourself from the liability for cross-contamination? Do
            you need a backflow preventer device or flow control valve installed
            or tested, or a cross-contamination solution installed? If so{' '}
            <Link to="/contact-us#email">contact us</Link> now.
          </p>

          <h2>We work with Trusted Manufacturers</h2>
          <p>
            We sell and install{' '}
            <a
              title="Watts
            Backflow Preventers"
              href="http://www.wattscanada.ca/backflowprevention"
              target="_blank"
              rel="noopener noreferrer"
            >
              Watts Backflow Preventers
            </a>
            ,{' '}
            <a
              href="http://www.zurn.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              ZURN Wilkins Backflow Preventers
            </a>
            &nbsp;and backflow preventers from other leading
            manufacturers.&nbsp;Let ARC be your certified backflow tester.
            <div className="feature">
              <Img fixed={wattsLogo.childImageSharp.fixed} alt="Watts Canada" />
              <Img fixed={zurnLogo.childImageSharp.fixed} alt="ZURN Canada" />
            </div>
          </p>
        </div>
      </Row>
    </div>

    <CTA />

    <div className="content">
      <h2>Have you received a Warning Letter?</h2>

      <p>
        Have you received any of these warning letters regarding backflow
        prevention from your municipality? Look over the sample letters below.
        Get in touch with us to resolve the matter in a timely manner. (todo:
        expand){' '}
      </p>

      <Row>
        <div>
          <h3>City of Toronto</h3>
          <Img
            fixed={imgSampleLetter.childImageSharp.fixed}
            alt="Sample City of Toronto Backflow Warning Letter"
          />
        </div>
        <div>
          <h3>Barrie</h3>
          <Img
            fixed={imgSampleLetter.childImageSharp.fixed}
            alt="Sample City of Toronto Backflow Warning Letter"
          />
        </div>
        <div>
          <h3>Mississagua</h3>
          <Img
            fixed={imgSampleLetter.childImageSharp.fixed}
            alt="Sample City of Toronto Backflow Warning Letter"
          />
        </div>
        <div>
          <h3>Peterborough</h3>
          <Img
            fixed={imgSampleLetter.childImageSharp.fixed}
            alt="Sample City of Toronto Backflow Warning Letter"
          />
        </div>
      </Row>

      <h2>Certified Backflow and Cross-Connection Testing Specialists</h2>
      <p>
        Our plumbers hold the required&nbsp;
        <strong>
          Certificate of Achievement in Cross Connection Control
        </strong>{' '}
        endorsed by the{' '}
        <a href="http://www.owwa.com/hm/index.php">
          Ontario Water Works Association (OWWA)
        </a>
        , and have completed the{' '}
        <strong>Cross Connection Control Specialist (CCCS)</strong> course
        offered by the OWWA. We also own and maintain our own NIST-certified
        testing equipment. This uniquely equips us for premise isolation and
        backflow prevention projects, and will allow us to quickly install a new
        backflow preventer or flow-control valve, or provide backflow preventer
        certification to an existing installation.
      </p>
    </div>
    <Reviews />
    <div className="content">
      <h2>Backflow Preventer Device Testing ensures Compliance with the Law</h2>
      <p>
        In the wake of the Walkerton Tragedy the Province of Ontario created the
        Safe Drinking Water Act. As a result, many municipalities crafted new
        bylaws to protect the water supply. These bylaws now hold
        property-owners and tenants responsible for ensuring that their internal
        systems cannot backflow into the water supply, causing
        cross-contamination and illness. A backflow preventer device, sometimes
        called a check valve, isolates the municipal water supply from the
        building water system keeping the water supply safe.
      </p>
      <p>
        Municipalities that have passed Backflow Prevention bylaws, or Water
        Supply Bylaws mandating the inspection and proper use of backflow
        preventers include:
      </p>
      <ul>
        <li>
          <a
            href="http://www.toronto.ca/water/protecting_quality/backflow_prevention/"
            target="_blank"
            rel="noopener noreferrer"
          >
            The City of Toronto
          </a>
        </li>
        <li>
          <a
            href="http://www.barrie.ca/Living/Water/Operations/Pages/BackflowPrevention.aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            Barrie (Bylaw 2010-102)
          </a>
        </li>
        <li>
          <a
            href="http://www.brantford.ca/residents/health/water_quality/programs/BackflowPrevention/Pages/default.aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            Brantford (Bylaw 75-2007)
          </a>
        </li>
        <li>
          <a
            href="http://www.cambridge.ca/planning_services/building/backflow_prevention"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cambridge (Bylaw 146-03)
          </a>
        </li>
        <li>
          <a
            href="http://www.collus.com/water_bylaw"
            target="_blank"
            rel="noopener noreferrer"
          >
            Collingwood (Bylaw 2005-64)
          </a>
        </li>
        <li>
          <a
            title="City of Guelph Backflow Prevention Information"
            href="http://guelph.ca/living.cfm?subCatID=1392&amp;smocid=1975"
            target="_blank"
            rel="noopener noreferrer"
          >
            Guelph (Bylaw 2008-18660)
          </a>
        </li>
        <li>
          <a
            title="Halton Region (Bylaw 61-11)"
            href="http://www.halton.ca/cms/one.aspx?objectId=12003"
            target="_blank"
            rel="noopener noreferrer"
          >
            Halton Region (Bylaw 61-11)
          </a>
          ; includes Oakville, Milton, Burlington, Georgetown, Halton Hills and
          Campbellville
        </li>
        <li>
          <a
            href="http://www.hamilton.ca/CityDepartments/PublicWorks/WaterAndWasteWaterDev/Programs/backflow_prevention.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hamilton (Bylaw 10-103)
          </a>
        </li>
        <li>
          <a
            href="http://www.kitchener.ca/en/businessinkitchener/Backflow_prevention.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kitchener
          </a>
        </li>
        <li>
          <a href="http://www.markham.ca/wps/portal/Markham/Residents/Water/BackflowPreventionProgram/!ut/p/c4/04_SB8K8xLLM9MSSzPy8xBz9CP0os3gTiyBHZzc_g0Bz8xBTA09DJ09HLzdnY0tnQ_2CbEdFAGT-VwI!/">
            Markham
          </a>
        </li>
        <li>
          <a
            title="Town of Midland Cross Connection Control Program"
            href="http://town.midland.on.ca/town-hall/Departments-Services/WasteWaterTreatmentCentre/CrossConnectionControlProgram/index.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Midland (Bylaw 2005-20)
          </a>
        </li>
        <li>
          <a href="http://www.peelregion.ca/pw/water/sewage-trtmt/basement-flooding.htm">
            Region of Peel
          </a>
          ; includes Mississauga, Brampton, and the Town of Caledon
        </li>
        <li>
          <a
            href="https://penetanguishene.civicweb.net/Documents/DocumentList.aspx?ID=91051"
            target="_blank"
            rel="noopener noreferrer"
          >
            Penetanguishene (By-Law 2009-105)
          </a>
        </li>
        <li>
          <a
            title="City of Peterborough Cross Connection Control Program"
            href="http://www.peterboroughutilities.ca/Water/Cross_Connection_Control_Program.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Peterborough
          </a>
        </li>
        <li>
          <a
            href="http://www.stcatharines.ca/en/livein/BackflowPreventionProgram.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            St. Catharines (Bylaw 2005-200)
          </a>
        </li>
        <li>
          <a
            href="http://www.city.waterloo.on.ca/DesktopDefault.aspx?tabid=2430"
            target="_blank"
            rel="noopener noreferrer"
          >
            Waterloo (Bylaw 2010-040)
          </a>
        </li>
      </ul>
      <p>
        All installations of the premise isolation devices and all related items
        should be done in accordance with the Building Code, Canadian Standards
        Association (CSA) -B64 series standard, Municipal Code Chapter 851 and
        manufacturers specifications.
      </p>
      <h2>
        A Full Service Plumbing Contractor <em>and</em>&nbsp;General Contractor
      </h2>
      <p>
        ARC will provide you with a&nbsp;
        <strong>Backflow Preventer (BFP) Device Testing Survey</strong>&nbsp;and
        detailed test report demonstrating your compliance with the law. As a
        licensed <Link to="/plumbing/">plumber</Link>, and full-line{' '}
        <Link to="/commercial/">General Contractor</Link>, we are equipped to be
        your complete clean-water solution provider. We sell, install, service,
        and maintain the equipment you need in order to comply with the law and
        protect our water supply. <Link to="/contact-us/">Contact us</Link>
        &nbsp;now!
      </p>
    </div>
  </Layout>
)

export default Page

export const query = graphql`
  query BackflowQuery {
    site {
      siteMetadata {
        title
      }
    }
    imgPipes: file(relativePath: { regex: "/pipes.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
        original {
          width
        }
      }
    }
    imgBackflowValve: file(relativePath: { regex: "/backflow-valve.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
        original {
          width
        }
      }
    }
    imgBackflowTrench: file(relativePath: { regex: "/backflow-trench.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
        original {
          width
        }
      }
    }
    imgSampleLetter: file(relativePath: { regex: "/warning-letter.jpg$/" }) {
      childImageSharp {
        fixed(height: 180) {
          ...GatsbyImageSharpFixed
        }
        original {
          width
        }
      }
    }
    wattsLogo: file(relativePath: { regex: "/watts-logo/" }) {
      childImageSharp {
        fixed(grayscale: true) {
          ...GatsbyImageSharpFixed
        }
        original {
          width
        }
      }
    }
    zurnLogo: file(relativePath: { regex: "/zurn-logo/" }) {
      childImageSharp {
        fixed(grayscale: true) {
          ...GatsbyImageSharpFixed
        }
        original {
          width
        }
      }
    }
  }
`
