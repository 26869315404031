import React from 'react'
import './reviews.scss'
import { FaGoogle } from 'react-icons/fa'
import { FiArrowRight } from 'react-icons/fi'
import Row from 'src/components/row'

export default () => (
  <div className="reviews">
    <h1 className="center">Our Reviews Speak for Themselves</h1>
    <div className="center">
      <a
        className="button review-google"
        href="https://search.google.com/local/writereview?placeid=ChIJCcPiesU3K4gRmk4HLzUQ0MY"
      >
        <FaGoogle /> Review us
      </a>
      <a
        className="button review-google"
        href="https://www.google.com/maps/search/?api=1&query=ARC%20General%20Contracting&query_place_id=ChIJCcPiesU3K4gRmk4HLzUQ0MY"
      >
        Read more reviews <FiArrowRight />
      </a>
    </div>
    <div className="reviews-wrapper feature">
      <div className="review">
        <div className="review-author">Adam Hess</div>
        <div className="review-stars">★★★★★</div>
        <div className="review-body">
          Called Arc and spoke to John about replacing a toilet in my second
          floor bathroom. He was responsive, professional, clean and respectful
          in my home. I was impressed and have since referred him to a co-worker
          and a couple of neighbours. Happy to recommend his work.
        </div>
      </div>
      <div className="review">
        <div className="review-author">Jude Lopez</div>
        <div className="review-stars">★★★★★</div>
        <div className="review-body">
          Thank god for honest tradesman, he will have mine and all of my
          friends, family and associates business from now on. Awesome!
        </div>
      </div>
      <div className="review">
        <div className="review-author">S. Sepehr</div>
        <div className="review-stars">★★★★★</div>
        <div className="review-body">
          I always have a hard time in Toronto to find an honest contractor who
          would provide a very good service and you can be certain he doesn't
          overcharge you for anything. Since my first call I have no hesitation
          in calling them again. That includes a replacement of two water
          heaters, for which I had to call John twice after they installed them.
          John came ASAP to fix the heaters' connection even though I had
          problem with electrical connection, which was not related to plumbing
          of water heaters.
        </div>
      </div>
    </div>
  </div>
)
